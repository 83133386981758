import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useCurrentLang } from 'hooks/useCurrentLang';

import { Container, DangerouslySetInnerHtml, Layout } from 'layout';
import Button from 'common/Button';

import { NotFoundQueryResult } from './model';

import './404.scss';

const NotFoundPage = () => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const {
    allPageNotFound: { nodes },
    pageNotFound,
  }: NotFoundQueryResult = useStaticQuery(graphql`
    {
      allPageNotFound {
        nodes {
          lang
          text
        }
      }
      pageNotFound {
        text
        button404 {
          ariaLabel
          backgroundColor
          label
          link {
            url
          }
          size
        }
      }
    }
  `);

  const { text } = nodes.find((item) => item.lang === currentLangCode) || pageNotFound;
  const { button404 } = pageNotFound;
  const { ariaLabel, backgroundColor, size, label, link } = button404[0];
  const { url } = link[0];

  return (
    <Layout>
      <section className="not-found container-wrapper">
        <div className="not-found__holder">
          <Container fluid className="not-found__content">
            <div className="not-found__content-text">
              <DangerouslySetInnerHtml html={text} />
              <Button
                className="btn--sign-up"
                ariaLabel={ariaLabel}
                backgroundColor={backgroundColor}
                size={size}
                type="button"
                onClick={() => navigate(url)}
                label={label}
              />
            </div>
          </Container>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
